import { useLocalization } from "@fluent/react"
import {
  useEditorEffect,
  useEditorEventCallback,
  useEditorState,
} from "@nytimes/react-prosemirror"
import { useCallback, useContext, useEffect, useMemo } from "react"

import { HoverMenu, HoverMenuItem } from "@/components/hoverMenu/HoverMenu"
import { ChevronsLeft, ChevronsRight, Power } from "@/components/icons"
import { RevisionContext } from "@/contexts/RevisionContext"
import { chapterSchema } from "@/schemas/chapter/schema"
import { useAppDispatch, useAppSelector } from "@/store/hooks.ts"
import {
  getStepThroughState,
  getStoredScroll,
} from "@/store/selectors/viewerSelectors"
import { viewerSlice } from "@/store/slices/viewer"
import { getScrollContainer } from "@/utils/scroll"

export function StepThroughMenu() {
  const { revisionId } = useContext(RevisionContext)
  const stepThroughState = useAppSelector((state) =>
    getStepThroughState(state, revisionId)
  )
  const editorState = useEditorState()
  const dispatch = useAppDispatch()

  const { l10n } = useLocalization()

  const storedScroll = useAppSelector(getStoredScroll)

  const firstStepMarker = useMemo(() => {
    let stepPos: number | null = null
    editorState?.doc.forEach((node, pos) => {
      if (stepPos !== null) return
      if (node.type === chapterSchema.nodes.step_marker) {
        stepPos = pos
      }
    })
    return stepPos
  }, [editorState])

  const getScrollTop = useEditorEventCallback((view) => {
    const scrollContainer = getScrollContainer(view.dom)
    return scrollContainer.scrollTop
  })

  const getNextStepPos = useCallback(() => {
    if (!stepThroughState?.enabled) return null
    const { stepPos } = stepThroughState
    if (stepPos === null) return null

    let nextStepPos: number | null = null
    editorState?.doc.forEach((node, offset) => {
      if (nextStepPos !== null) return
      if (node.type === chapterSchema.nodes.step_marker && offset > stepPos) {
        nextStepPos = offset
      }
    })
    return nextStepPos
  }, [editorState?.doc, stepThroughState])

  const getPrevStepPos = useCallback(() => {
    if (!stepThroughState?.enabled) return null
    const { stepPos } = stepThroughState

    let nextStepPos: number | null = null
    editorState?.doc.forEach((node, offset) => {
      if (
        node.type === chapterSchema.nodes.step_marker &&
        (stepPos === null || offset < stepPos)
      ) {
        nextStepPos = offset
      }
    })
    return nextStepPos
  }, [editorState?.doc, stepThroughState])

  // In Chrome and Safari, changing the doc node results in
  // a frame where the doc view is empty, so the window height
  // shrinks, scrolling the viewport to the top.
  //
  // To avoid this jarring scroll to the top on every step,
  // we store the scroll position before changing the doc
  // and then restore it in a layout effect.
  useEditorEffect(
    (view) => {
      const scrollContainer = getScrollContainer(view.dom)
      if (storedScroll === null) return
      console.log("updating scroll top to", storedScroll)
      scrollContainer.scrollTop = storedScroll
    },
    [storedScroll]
  )

  useEditorEffect(
    (view) => {
      if (!stepThroughState?.enabled) return

      let lastStepNodePos = 0

      view.state.doc.forEach((node, pos) => {
        if (
          node.type === chapterSchema.nodes.step_marker &&
          (stepThroughState.stepPos === null || pos < stepThroughState.stepPos)
        ) {
          lastStepNodePos = pos
        }
      })

      const coords = view.coordsAtPos(lastStepNodePos)

      const scrollContainer = getScrollContainer(view.dom)
      scrollContainer.scrollTo({
        behavior: "smooth",
        top: coords.top - view.dom.getBoundingClientRect().top,
      })
    },
    [stepThroughState?.enabled, stepThroughState?.stepPos]
  )

  // Listen to ArrowRight, Space and ArrowLeft, Shift+Space keydown events
  // to navigate through steps
  useEffect(() => {
    function handleKeydown(event: KeyboardEvent) {
      if (!stepThroughState?.enabled) return
      // If there any editable fields or documents
      // on the screen, we don't want to intercept events
      // meant for them. Since the viewer isn't editable, we
      // only care about top-level events, targeting the body.
      if (event.target !== document.body) return

      if (
        event.key === "ArrowRight" ||
        (event.key === " " && !event.shiftKey)
      ) {
        dispatch(
          viewerSlice.actions.nextStepClicked({
            revisionId,
            scroll: getScrollTop(),
            stepPos: getNextStepPos(),
          })
        )
        event.preventDefault()
      } else if (
        event.key === "ArrowLeft" ||
        (event.key === " " && event.shiftKey)
      ) {
        event.preventDefault()
        dispatch(
          viewerSlice.actions.prevStepClicked({
            revisionId,
            scroll: getScrollTop(),
            stepPos: getPrevStepPos(),
          })
        )
        event.preventDefault()
      }
    }

    document.addEventListener("keydown", handleKeydown)
    return () => {
      document.removeEventListener("keydown", handleKeydown)
    }
  }, [
    dispatch,
    getNextStepPos,
    getPrevStepPos,
    getScrollTop,
    revisionId,
    stepThroughState?.enabled,
  ])

  if (!stepThroughState?.enabled) return null

  return (
    <HoverMenu>
      <HoverMenuItem
        label="Previous"
        labelFluentId="step-through-view-previous"
        description="View the previous step in the case"
        descriptionFluentId="step-through-view-previous"
        disabled={stepThroughState.stepPos === firstStepMarker}
        handleAddClick={() => {
          dispatch(
            viewerSlice.actions.prevStepClicked({
              revisionId,
              scroll: getScrollTop(),
              stepPos: getPrevStepPos(),
            })
          )
        }}
        icon={<ChevronsLeft />}
      />
      <HoverMenuItem
        label={
          stepThroughState.enabled
            ? l10n.getString("step-through-exit", null, "Deactivate")
            : l10n.getString("step-through-enter", null, "Activate")
        }
        description="Exit step through mode"
        descriptionFluentId="step-through-exit"
        handleAddClick={() => {
          dispatch(
            viewerSlice.actions.stepThroughDisabled({
              revisionId,
              scroll: getScrollTop(),
            })
          )
        }}
        icon={<Power />}
      />
      <HoverMenuItem
        label="Next"
        labelFluentId="step-through-view-next"
        description="View the next step in the case"
        descriptionFluentId="step-through-view-next"
        disabled={stepThroughState.stepPos === null}
        handleAddClick={() => {
          dispatch(
            viewerSlice.actions.nextStepClicked({
              revisionId,
              scroll: getScrollTop(),
              stepPos: getNextStepPos(),
            })
          )
        }}
        icon={<ChevronsRight />}
        iconSide="right"
      />
    </HoverMenu>
  )
}
