import { Popover, usePopoverContext } from "@ariakit/react/popover"
import {
  useEditorEventCallback,
  useEditorEventListener,
} from "@nytimes/react-prosemirror"
import {
  type ComponentProps,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
} from "react"

/**
 * Attaches an event handler to the Document's selectionchange
 * event.
 *
 * By default, the Document will emit selectionchange events continuously
 * while the user is dragging over text. This can be noisy for visual
 * elements that track the selection, so this hook instead mutes these
 * events while the user is dragging to select, and only emits when
 * they have released their mouse. Modifying the selection with a keyboard
 * works as expected.
 */
function useOnSelectionChange(handler: () => void) {
  const mouseDownRef = useRef(false)

  useEffect(() => {
    function onSelectionChange() {
      if (mouseDownRef.current) return
      handler()
    }

    document.addEventListener("selectionchange", onSelectionChange)
    return () => {
      document.removeEventListener("selectionchange", onSelectionChange)
    }
  }, [handler])

  useEditorEventListener("mousedown", () => {
    mouseDownRef.current = true
  })

  useEditorEventListener("mouseup", () => {
    mouseDownRef.current = false
    handler()
  })
}

type Props = {
  children?: ReactNode
  className?: string | undefined
}

/**
 * Wrapper around AriaKit's Popover component that always positions itself
 * relative to the current text selection.
 */
export function TextSelectionPopover({ children, className }: Props) {
  const ref = useRef<HTMLDivElement | null>(null)

  const popover = usePopoverContext()

  // Use the Document's selection rect as the anchor rect. This will
  // always use the first selection range in a multi-range selection.
  const getAnchorRect: ComponentProps<typeof Popover>["getAnchorRect"] =
    useCallback(() => {
      return (
        document.getSelection()?.getRangeAt(0).getBoundingClientRect() ?? null
      )
    }, [])

  const updatePopover = useEditorEventCallback((view) => {
    const selection = document.getSelection()

    // Check if the selection is outside the Prosemirror editor
    const isSelectionInsideProsemirror =
      selection && view.dom.contains(selection.anchorNode)

    if (!selection || selection.isCollapsed || !isSelectionInsideProsemirror) {
      popover?.hide()
      return
    }

    popover?.render()
    popover?.show()
  })

  useOnSelectionChange(updatePopover)

  if (!popover) return null

  return (
    <Popover
      store={popover}
      className={className}
      ref={ref}
      getAnchorRect={getAnchorRect}
      autoFocusOnShow={false}
      hideOnInteractOutside={true}
      hideOnEscape={true}
      unmountOnHide={true}
      flip={false}
    >
      {children}
    </Popover>
  )
}
