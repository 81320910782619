import { Button } from "@ariakit/react"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import { DOMSerializer, Node } from "prosemirror-model"
import { useContext, useLayoutEffect, useMemo, useRef } from "react"

import { FlashcardEditor } from "./FlashcardEditor"
import styles from "./flashcard.module.css"

import { EditorErrorFallback } from "@/components/editor/Editor"
import { Pencil, Trash } from "@/components/icons"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useAsidePortal } from "@/hooks/useAsidePortal"
import { useHighlightAttachment } from "@/hooks/useHighlightAttachment"
import { flashcardSchema } from "@/schemas/flashcard/schema"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { getRevisionEditorState } from "@/store/selectors/documentsSelectors"
import { getFlashcardState } from "@/store/selectors/flashcardSelectors"
import {
  useDeleteFlashcardMutation,
  useGetFlashcardLatestStepsQuery,
  useGetFlashcardQuery,
  useGetFlashcardRevisionQuery,
} from "@/store/slices/api"
import { flashcardEditClicked, flashcardSaved } from "@/store/store"
import { ErrorBoundary } from "@/utils/error"

type Props = {
  highlightId: UUID
  flashcardId: UUID
}

export function Flashcard({ highlightId, flashcardId }: Props) {
  const {
    top,
    visible,
    ref: flashcardRef,
  } = useHighlightAttachment<HTMLDivElement>(highlightId)
  const { revisionId: chapterRevisionId } = useContext(RevisionContext)

  const flashcardState = useAppSelector((state) =>
    getFlashcardState(state, chapterRevisionId, highlightId)
  )

  const dispatch = useAppDispatch()

  const isEditing = flashcardState?.isEditing ?? false

  const { data: flashcard, isLoading: isFlashcardLoading } =
    useGetFlashcardQuery(flashcardId)

  const revisionId = flashcard?.draftRevisionId

  const { data: revision, isLoading: isRevisionLoading } =
    useGetFlashcardRevisionQuery(
      revisionId ? { flashcardId, revisionId } : skipToken
    )

  useGetFlashcardLatestStepsQuery(
    revision
      ? {
          flashcardId: flashcardId,
          revisionId: revision.id,
          version: revision.snapshot?.version ?? 0,
        }
      : skipToken
  )

  const revisionContextValue = useMemo(
    () =>
      revisionId && {
        revisionId,
        documentType: "flashcard" as const,
        documentId: flashcardId,
      },
    [revisionId, flashcardId]
  )

  const editorState = useAppSelector((state) =>
    getRevisionEditorState(state, revisionId)
  )

  const [deleteFlashcard] = useDeleteFlashcardMutation()

  return useAsidePortal(
    visible ? (
      <div
        ref={flashcardRef}
        data-preferred-top={top}
        data-is-anchor={false}
        data-tie-breaker={1}
        className={styles["flashcardWrapper"]}
      >
        {!isFlashcardLoading &&
        !isRevisionLoading &&
        !flashcardState?.isLoading &&
        editorState &&
        revisionContextValue ? (
          isEditing ? (
            <RevisionContext.Provider value={revisionContextValue}>
              <ErrorBoundary fallback={<EditorErrorFallback />}>
                <FlashcardEditor
                  flashcardId={flashcardId}
                  editorState={editorState}
                  onDone={() => {
                    dispatch(
                      flashcardSaved({
                        highlightId,
                        revisionId: chapterRevisionId,
                      })
                    )
                  }}
                />
              </ErrorBoundary>
            </RevisionContext.Provider>
          ) : (
            <FlashcardRenderer
              flashcardDoc={editorState.doc}
              onEditClick={() => {
                dispatch(
                  flashcardEditClicked({
                    highlightId,
                    revisionId: chapterRevisionId,
                  })
                )
              }}
              onDeleteClick={() => {
                deleteFlashcard({
                  flashcardId,
                  highlightId,
                  revisionId: chapterRevisionId,
                })
              }}
            />
          )
        ) : (
          "Loading..."
        )}
      </div>
    ) : null,
    highlightId
  )
}

/**
 * This helper component serializes a footnote node into HTML,
 * based on the dskrpt schema.
 */

function FlashcardRenderer({
  flashcardDoc,
  onEditClick,
  onDeleteClick,
}: {
  flashcardDoc: Node
  onEditClick: () => void
  onDeleteClick: () => void
}) {
  const ref = useRef<HTMLDivElement | null>(null)

  const serializer = useMemo(
    () => DOMSerializer.fromSchema(flashcardSchema),
    []
  )

  const content = serializer.serializeFragment(flashcardDoc.content)

  useLayoutEffect(() => {
    ref.current?.replaceChildren(content)
  }, [content])

  return (
    <div className={styles["flashcard"]}>
      <div className={styles["flashcardRendererButtonGroup"]}>
        <Button
          className={styles["flashcardRendererButton"]}
          onClick={onEditClick}
        >
          <Pencil />
        </Button>
        <Button
          className={styles["flashcardRendererButton"]}
          onClick={onDeleteClick}
        >
          <Trash />
        </Button>
      </div>
      <div
        // Keep the ProseMirror class to ensure
        // all FlashcardEditor styles are applied
        className={`ProseMirror`}
        ref={ref}
        contentEditable={false}
      />
    </div>
  )
}
