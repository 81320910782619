import { useEditorEffect } from "@nytimes/react-prosemirror"
import { useRef, useState } from "react"

import type { UUID } from "@/store/UUID"
import { useNodeFilter } from "@/store/selectors/viewerSelectors"

export function useHighlightAttachment<E extends HTMLElement>(
  highlightId: UUID
) {
  const [top, setTop] = useState(0)
  const [visible, setVisible] = useState(false)
  const ref = useRef<E | null>(null)

  const nodeFilter = useNodeFilter()

  useEditorEffect(
    (view) => {
      function getPreferredTop() {
        const highlight = document.getElementById(highlightId)
        if (!highlight) return

        const pos = view.posAtDOM(highlight, 0)
        const $pos = view.state.doc.resolve(pos)
        setVisible(nodeFilter($pos.node(1), $pos.before(1)))
        setTop(
          (view.coordsAtPos(pos)?.top ?? 0) -
            (view.dom.parentElement?.getBoundingClientRect().top ?? 0)
        )
      }
      const resizeObserver = new ResizeObserver(getPreferredTop)
      resizeObserver.observe(
        document.getElementById("aside-container") as HTMLDivElement
      )
      getPreferredTop()
      return () => resizeObserver.disconnect()
    },
    [highlightId, nodeFilter]
  )

  return { top, visible, ref }
}
