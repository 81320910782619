import { type ReactNode, useContext } from "react"
import { createPortal } from "react-dom"

import { AsidePortalContext } from "@/contexts/AsidePortalContext"

export function useAsidePortal(element: ReactNode, key: string) {
  const asideRef = useContext(AsidePortalContext)

  return asideRef && createPortal(element, asideRef, key)
}
