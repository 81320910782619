import { Button } from "@ariakit/react"
import {
  type NodeViewComponentProps,
  useEditorEffect,
  useEditorEventListener,
} from "@nytimes/react-prosemirror"
import { NodeSelection } from "prosemirror-state"
import { forwardRef, useCallback, useContext, useMemo, useState } from "react"

import { FootnoteEditor } from "./FootnoteEditor"
import footnoteStyles from "./footnote.module.css"

import { useMobileAsideDrawer } from "@/components/aside/useMobileAsideDrawer"
import { RevisionContext } from "@/contexts/RevisionContext"
import { useAsidePortal } from "@/hooks/useAsidePortal"
import type { UUID } from "@/store/UUID"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { useNodeFilter } from "@/store/selectors/viewerSelectors"
import { atomNodeActivated, toggleFootnoteVisibility } from "@/store/store"

export const Footnote = forwardRef<HTMLSpanElement, NodeViewComponentProps>(
  function Footnote({ children: _, nodeProps, ...props }, ref) {
    const { node, pos, isSelected } = nodeProps
    const nodeFilter = useNodeFilter()
    const atomVisible = useMemo(
      () => nodeFilter(node, pos),
      [node, nodeFilter, pos]
    )
    const [top, setTop] = useState(0)

    const { documentId: chapterId, revisionId } = useContext(RevisionContext)
    const dispatch = useAppDispatch()

    const guid = node.attrs["guid"] as UUID
    const { visible, number } =
      useAppSelector((state) => !!guid && state.revisions.footnotes[guid]) || {}

    const asideDrawer = useMobileAsideDrawer()

    const openAtomEditor = useCallback(() => {
      /** Mobile */
      if (asideDrawer) {
        if (!visible) dispatch(toggleFootnoteVisibility({ footnoteId: guid }))
        asideDrawer.open()
      }

      /** Desktop */
      if (!asideDrawer) {
        dispatch(toggleFootnoteVisibility({ footnoteId: guid }))
        // Only run the action if the footnotes becomes visible
        if (!visible) dispatch(atomNodeActivated({ revisionId, pos }))
      }
    }, [asideDrawer, visible, dispatch, guid, revisionId, pos])

    useEditorEventListener("keydown", (view, event) => {
      if (event.key !== "Enter" && event.key !== " ") return false

      const { selection } = view.state
      if (!(selection instanceof NodeSelection && selection.node === node))
        return false

      event.preventDefault()
      openAtomEditor()
      return true
    })

    useEditorEffect(
      (view) => {
        function getPreferredTop() {
          if (pos === undefined) return

          setTop(
            (view.coordsAtPos(pos)?.top ?? 0) -
              (view.dom.parentElement?.getBoundingClientRect().top ?? 0)
          )
        }

        // Attach a resize observer to the aside container to recompute the
        // footnote's preferred top position when the aside container is resized.
        const resizeObserver = new ResizeObserver(getPreferredTop)
        resizeObserver.observe(
          document.getElementById("aside-container") as HTMLDivElement
        )
        getPreferredTop()
        return () => resizeObserver.disconnect()
      },
      [pos]
    )

    const portalElement = useAsidePortal(
      atomVisible ? (
        <div
          data-preferred-top={top}
          data-is-anchor={isSelected}
          data-tie-breaker={number}
          data-footnote-guid={guid}
          hidden={!visible}
        >
          <Button
            onClick={openAtomEditor}
            className={footnoteStyles["footnote"]}
          >
            <sup>{number}</sup>
          </Button>
          <FootnoteEditor
            chapterId={chapterId}
            revisionId={revisionId}
            footnote={node}
            pos={pos}
            isSelected={isSelected}
          />
        </div>
      ) : null,
      guid
    )

    return (
      <span
        {...props}
        ref={ref}
        suppressContentEditableWarning
        contentEditable={false}
      >
        <Button onClick={openAtomEditor} className={footnoteStyles["footnote"]}>
          <sup data-guid={guid}>{number}</sup>
        </Button>
        {portalElement}
      </span>
    )
  }
)
