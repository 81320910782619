import { createSelector } from "@reduxjs/toolkit"
import type { Node } from "prosemirror-model"
import { useContext, useMemo } from "react"

import { RevisionContext } from "@/contexts/RevisionContext"
import type { UUID } from "@/store/UUID"
import { useAppSelector } from "@/store/hooks"
import type { RootState } from "@/store/store"

export function getMarkerMode(state: RootState) {
  return state.viewer.marker.enabled
}

function getMarkerCurrentState(state: RootState) {
  return state.viewer.marker.current
}

export const getCurrentMark = createSelector(
  getMarkerCurrentState,
  (markerCurrentState) => {
    return markerCurrentState
      ? {
          anchor: markerCurrentState.anchor,
          head: markerCurrentState.head,
          from: Math.min(markerCurrentState.anchor, markerCurrentState.head),
          to: Math.max(markerCurrentState.anchor, markerCurrentState.head),
          empty: markerCurrentState.anchor === markerCurrentState.head,
        }
      : null
  }
)

export function getMarkerColor(state: RootState) {
  return state.viewer.marker.color
}

export function getStepThroughState(state: RootState, revisionId: UUID) {
  return state.viewer.stepThrough[revisionId]
}

export const getNodeFilter = createSelector(
  getStepThroughState,
  (stepThroughState) => {
    if (!stepThroughState?.enabled) return () => true

    const { stepPos } = stepThroughState
    if (stepPos === null) return () => true

    return (_node: Node, pos: number) => {
      return pos <= stepPos
    }
  }
)

export function makeGetNodeFilter(revisionId: UUID) {
  return (state: RootState) => getNodeFilter(state, revisionId)
}

export function useNodeFilter() {
  const { revisionId } = useContext(RevisionContext)
  const getNodeFilter = useMemo(
    () => makeGetNodeFilter(revisionId),
    [revisionId]
  )
  return useAppSelector(getNodeFilter)
}

export function getStoredScroll(state: RootState) {
  return state.viewer.storedScroll
}

export function getActiveHighlightId(state: RootState) {
  return state.viewer.activeHighlightId
}
