import clsx from "clsx"
import type { EditorState } from "prosemirror-state"
import { Decoration } from "prosemirror-view"

import type { UUID } from "@/store/UUID"
import type { Highlight } from "@/types/api"

export function makeHighlightDecorations(
  editorState?: EditorState | null,
  activeHighlightId?: UUID | null,
  highlights?: Highlight[],
  markerColor?: string | null,
  currentMark?: { from: number; to: number } | null
) {
  if (!editorState?.doc || (!highlights?.length && !currentMark)) return []

  const decorations =
    highlights?.map((highlight) =>
      Decoration.inline(
        highlight.from,
        highlight.to,
        {
          id: highlight.id,
          class: clsx(`highlight`, `highlight-${highlight.color}`, {
            "highlight-thread": highlight.threadId || highlight.flashcardId,
            "highlight-active": activeHighlightId === highlight.id,
          }),
          "data-thread-id": highlight.threadId || "",
        },
        { type: "highlight", highlight }
      )
    ) || []

  if (currentMark && markerColor) {
    decorations.push(
      Decoration.inline(
        currentMark.from,
        currentMark.to,
        {
          id: "draft-highlight",
          class: `highlight highlight-${markerColor}`,
        },
        { type: "highlight", highlight: {} }
      )
    )
  }

  return decorations
}
