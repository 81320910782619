import type { Node } from "prosemirror-model"
import type { EditorState } from "prosemirror-state"
import { Decoration } from "prosemirror-view"

export function makeNodeFilterDecorations(
  editorState: EditorState,
  filter: (node: Node, pos: number) => boolean,
  createWidget?: (node: Node, pos: number) => Decoration
) {
  if (!editorState) return []

  const decorations: Decoration[] = []

  editorState.doc.forEach((node, offset) => {
    if (filter(node, offset)) return

    decorations.push(
      Decoration.node(offset, offset + node.nodeSize, {
        style: "display: none",
      })
    )

    if (createWidget) {
      const widget = createWidget(node, offset)
      decorations.push(widget)
    }
  })

  return decorations
}
